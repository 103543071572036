<template>
  <v-main>
    <v-container
      fluid
      pa-0
      class="d-flex flex-column flex-grow-1 fill-parent-height"
    >
      <v-row no-gutters class="top-row flex-grow-0 flex-shrink-0 pt-3 pl-3">
        <v-col cols="2">
          <v-select
            class="mx-3"
            :items="regionItems"
            :value="activeRegion"
            return-object
            :item-text="getRegionName"
            @change="changeRegionRoute"
          >
            <template v-slot:label>
              <span style="color: #1D4180">{{ $t("monitoring.region") }}</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="2">
          <v-select
            class="mx-3"
            v-model="selectedLayer"
            :items="productItems"
            :item-text="item => item.title"
            :item-value="item => item.layerId"
            @change="fetchAlertItems"
          >
            <template v-slot:label>
              <span style="color: #1D4180">{{ $t("monitoring.layer") }}</span>
            </template>
          </v-select>
        </v-col>
        <!-- Start Date -->
        <v-col cols="2" class="mr-1">
          <v-menu
            v-model="startDateCalenderVisibility"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mx-3"
                v-bind="attrs"
                v-on="on"
                v-model="startDate"
                clearable
                @input="handleStartDateInput"
              >
                <template v-slot:label>
                  <span style="color: #1D4180">{{
                    $t("monitoring.startDate")
                  }}</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @input="handleStartDateSelect"
              :min="firstSceneDate"
              :max="todaysDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- End Date -->
        <v-col cols="2 ">
          <v-menu
            v-model="endDateCalenderVisibility"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                class="mx-3"
                v-on="on"
                v-model="endDate"
                clearable
                @input="handleEndDateInput"
              >
                <template v-slot:label>
                  <span style="color: #1D4180">{{
                    $t("monitoring.endDate")
                  }}</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @input="handleEndDateSelect"
              :min="firstSceneDate"
              :max="todaysDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-checkbox
            class="mx-3"
            v-model="allDataCheckbox"
            @change="onCheckboxChange"
            :label="$t('monitoring.allData')"
          >
          </v-checkbox>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="text-sm-right">
          <v-btn
            class="ma-3"
            color="primary"
            :to="{ name: 'GlobalsThresholds' }"
            >{{ $t("monitoring.setThresholds") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="top-row flex-grow-0 flex-shrink-0 pl-3">
        <v-col cols="auto">
          <!--          <v-text-field-->
          <!--            class="pl-3 pt-0"-->
          <!--            v-model="searchTerm"-->
          <!--            hide-details-->
          <!--            :label="$t('monitoring.searchStation')"-->
          <!--            clearable-->
          <!--          />-->
        </v-col>
      </v-row>
      <v-row
        v-if="
          !virtualStations.length ||
            !virtualStations.filter(
              station => station.geometry.type === 'Polygon'
            ).length
        "
      >
        <empty-state-vs v-if="vsLoaded"></empty-state-vs>
      </v-row>
      <v-row
        v-if="
          virtualStations.filter(station => station.geometry.type === 'Polygon')
            .length && !productItems.length
        "
      >
        <empty-state-thresholds v-if="vsLoaded"></empty-state-thresholds>
      </v-row>
      <v-row
        v-if="virtualStations.length && productItems.length"
        no-gutters
        class="top-row flex-grow-1 flex-shrink-1 pa-3 pt-0"
      >
        <empty-state-timeseries
          v-if="alertItemsLoaded && !alertItems.length"
          :mode="'alert'"
          :start-date="startDate"
          :end-date="endDate"
          :product-items="productItems"
          :layer="selectedLayer"
        ></empty-state-timeseries>
        <v-col cols="6" class="list-items fill-parent-height pa-3">
          <div v-if="!alertItemsLoaded">
            <v-overlay opacity="0.2" absolute>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="64"
                ></v-progress-circular>
                <div>{{ $t("loading") }}</div>
              </div>
            </v-overlay>
          </div>
          <div>
            <v-row class="pl-6 pr-5" style="font-size: 14px" align="center">
              <v-col cols="3">
                <span @click="sortName = !sortName">
                  {{ $t("virtualStations") }}</span
                >
                <v-btn icon @click="sortName = !sortName">
                  <v-icon
                    >{{ sortName ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  class=" mb-0"
                  :color="showSearchTerm ? 'primary' : ''"
                  @click="showSearchTerm = !showSearchTerm"
                >
                  <v-icon label="station">mdi-magnify</v-icon>
                </v-btn>
              </v-col>

              <v-col
                v-if="this.$appConfig.keycloakClient !== 'coasts'"
                cols="3"
                @click="sortCurrentValue = !sortCurrentValue"
                >{{ $t("monitoring.currentStatus") }}
                <v-btn icon>
                  <v-icon
                    >{{
                      sortCurrentValue ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" v-if="this.$appConfig.keycloakClient !== 'coasts'"
                >{{ $t("monitoring.previousStatus") }}
              </v-col>
              <v-spacer
                v-if="this.$appConfig.keycloakClient === 'coasts'"
              ></v-spacer>
              <v-col cols="3" @click="sortValue = !sortValue"
                >{{ $t("monitoring.alerts") }}
                <v-btn icon>
                  <v-icon
                    >{{ sortValue ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </v-btn>
                / {{ $t("monitoring.warnings") }}
              </v-col>
            </v-row>
            <v-row
              v-if="showSearchTerm"
              no-gutters
              class="top-row flex-grow-0 flex-shrink-0 pl-3"
            >
              <v-col cols="auto">
                <v-text-field
                  class="pl-3 pt-0"
                  autofocus
                  v-model="searchTerm"
                  :label="$t('monitoring.searchStation')"
                  clearable
                />
              </v-col>
            </v-row>
            <alert-list-item
              v-for="alertItem in filteredAlertItems"
              :key="alertItem.id"
              :alert-item="alertItem"
              :product="selectedProduct"
              :region="activeRegion.id"
              :dateRange="selectedDateRange"
              :reference-date="selectedReferenceDate"
            />
          </div>
        </v-col>
        <v-col cols="6" class="pa-3 fill-parent-height">
          <v-card
            elevation="2"
            class="fill-parent-height"
            v-if="filteredAlertItems"
          >
            <dashboard-map :alert-items="filteredAlertItems" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import regionMixin from "@/core/mixins/region.mixin";
import AlertListItem from "@/core/components/monitoring/AlertListItem";
import DashboardMap from "@/core/components/monitoring/DashboardMap";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment/moment";
import dashboardMixin from "@/core/mixins/dashboard.mixin";
import EmptyStateTimeseries from "@/core/components/EmptyStates/EmptyStateTimeseries.vue";
import EmptyStateVs from "@/core/components/EmptyStates/EmptyStateVS.vue";
import EmptyStateThresholds from "@/core/components/EmptyStates/EmptyStateThresholds.vue";

export default {
  name: "MonitoringDashboard",
  mixins: [regionMixin, dashboardMixin],
  components: {
    EmptyStateThresholds,
    EmptyStateVs,
    EmptyStateTimeseries,
    DashboardMap,
    AlertListItem
  },
  data: function() {
    return {
      productItems: [],
      allProductItems: new Set(),
      selectedProduct: "",
      selectedLayer: null,
      dateRangeItems: [
        { value: 7, text: this.$t("lastWeek") },
        { value: 30, text: this.$t("lastMonth") },
        { value: 90, text: this.$t("last3Months") },
        { value: 10000, text: this.$t("all") }
      ],
      selectedDateRange: 90,
      selectedReferenceDateMenu: false,
      selectedReferenceDate: moment().format("YYYY-MM-DD"),
      selectedRegion: null,
      alertItems: [],
      alertItemsLoaded: false,
      searchTerm: "",
      histDashboard: false,
      sortName: false,
      sortValue: false,
      sortCurrentValue: false,
      availableProducts: [],
      virtualStations: [],
      vsLoaded: false,
      startDateCalenderVisibility: false,
      endDateCalenderVisibility: false,
      startDate: null,
      endDate: null,
      todaysDate: moment().format("YYYY-MM-DD"),
      layersWithThreshold: [],
      showSearchTerm: false
    };
  },
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapState("dashboard", [
      "firstSceneDate",
      "allData",
      "selectedStartDate",
      "selectedEndDate"
    ]),
    ...mapState("raster", ["rasterLayer"]),
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    },
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    },
    regionItems() {
      return this.regions.features;
    },
    filteredAlertItems() {
      let searchResults = [];
      let allAlertItems = [];
      if (this.$appConfig.keycloakClient == "coasts") {
        allAlertItems = this.alertItems.filter(
          item => !item.name.includes("Profile_")
        );
      } else {
        allAlertItems = this.alertItems;
      }
      if (this.searchTerm) {
        allAlertItems.forEach(item =>
          this.filterAlertItemByName(item, searchResults)
        );
        return searchResults;
      } else {
        return allAlertItems;
      }
    },
    allDataCheckbox: {
      get() {
        return this.allData;
      },
      set(value) {
        this.setAllData(value);
      }
    }
  },

  methods: {
    ...mapActions("dashboard", [
      "setAvailableProducts",
      "setLayersWithRegionThreshold",
      "setAllData",
      "setSelectedStartDate",
      "setSelectedEndDate"
    ]),
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("app", ["showSnackbar"]),

    async fetchVirtualStation() {
      this.vsLoaded = false;
      const response = await axios.get(
        `/userdata/virtual-stations/?region=${this.$route.params.regionId}`
      );
      this.virtualStations = response.data;
      // if (response.data.length) {
      //   this.virtualStations = true;
      // } else {
      //   this.virtualStations = false;
      // }
      this.vsLoaded = true;
    },
    async fetchAvailableProducts() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.$route.params.regionId}&token=${this.accessToken}`
      );
      this.availableProducts = response.data.filter(
        product => product.product !== "rgb"
      );
      this.setAvailableProducts(this.availableProducts);
    },
    async fetchProductItems() {
      const response = await axios.get(
        `alerts/products/?region=${this.$route.params.regionId}`
      );
      if (response.data.length) {
        let filteredProducts = null;
        if (!this.isAdmin) {
          filteredProducts = response.data.filter(
            layer => layer.product !== "qut"
          );
        } else {
          filteredProducts = response.data;
        }
        const alertProductItems = filteredProducts.sort((a, b) =>
          a.product.localeCompare(b.product)
        );
        alertProductItems.forEach(item => {
          const product = this.availableProducts.filter(
            product => product.layerId === item.layer
          );
          if (product[0] != undefined) {
            this.productItems.push(product[0]);
          }
        });
      }
      this.productItems.forEach(item =>
        this.layersWithThreshold.push(item.layerId)
      );
      this.setLayersWithRegionThreshold(this.layersWithThreshold);
    },

    async fetchData() {
      await this.fetchProductItems();
      await this.fetchAlertItems();
    },

    async fetchAlertItems() {
      if (this.selectedDateRange >= 0) {
        this.alertItemsLoaded = false;
        const response = await axios.get(
          `alerts/?region=${this.$route.params.regionId}&layer=${this.selectedLayer}&days=${this.selectedDateRange}&reference_date=${this.selectedReferenceDate}`
        );
        this.alertItems = response.data;
        this.alertItemsLoaded = true;
      } else if (this.selectedDateRange < 0) {
        this.showSnackbar({
          show: true,
          message: this.$t("monitoring.negativeDateRange"),
          color: "error"
        });
      }
    },

    filterAlertItemByName(item, searchResults) {
      let searchAsRegEx = new RegExp(this.searchTerm, "gi");
      if (item.children) {
        item.children.forEach(childItem =>
          this.filterAlertItemByName(childItem, searchResults)
        );
      }
      if (item.name.match(searchAsRegEx)) {
        const newItem = { ...item };
        newItem.children = [];
        searchResults.push(newItem);
      }
    },
    onCheckboxChange(value) {
      this.setAllData(value);

      this.fetchTimestepsForLayersWithThreshold();
      if (this.allData) {
        this.selectedDateRange = 10000;
        this.startDate = this.firstSceneDate;
        this.endDate = this.todaysDate;
      } else {
        this.selectedDateRange = 90;
        this.endDate = this.selectedReferenceDate;
        this.startDate = moment()
          .subtract(90, "days")
          .format("YYYY-MM-DD");
      }
      this.fetchAlertItems();
    },
    handleStartDateSelect(date) {
      this.setSelectedStartDate(date);

      if (date && this.endDate) {
        this.startDate = date;
        this.startDateCalenderVisibility = false;
        this.selectedDateRange = moment(this.endDate).diff(
          moment(this.startDate),
          "days"
        );
        this.fetchAlertItems();
      } else if (date) {
        this.startDate = date;
        this.startDateCalenderVisibility = false;
        this.setAllData(false);
        this.selectedDateRange = 0;
        this.fetchAlertItems();
      } else if (!this.allData) {
        this.setAllData(false);
        this.selectedDateRange = 0;
        this.fetchAlertItems();
      }
      if (this.allData && this.startDate) {
        this.startDate = date;
        this.startDateCalenderVisibility = false;

        this.selectedDateRange = 0;
        this.fetchAlertItems();
      }
    },
    handleEndDateSelect(date) {
      this.setSelectedEndDate(date);

      if (date && this.startDate) {
        this.endDate = date;
        this.endDateCalenderVisibility = false;
        this.selectedDateRange = moment(this.endDate).diff(
          moment(this.startDate),
          "days"
        );
        this.selectedReferenceDate = this.endDate;

        this.fetchAlertItems();
      } else if (date) {
        this.endDate = date;
        this.endDateCalenderVisibility = false;
        this.setAllData(false);
        this.selectedDateRange = 0;
        this.fetchAlertItems();
      } else if (!this.allData) {
        this.setAllData(false);

        this.selectedDateRange = 0;
        this.fetchAlertItems();
      }
      if (this.allData && this.endDate) {
        this.endDate = date;
        this.endDateCalenderVisibility = false;

        this.selectedDateRange = 0;
        this.fetchAlertItems();
      }
    },
    handleStartDateInput() {
      if (!this.startDate) {
        this.handleStartDateSelect(null);
      }
    },
    handleEndDateInput() {
      if (!this.endDate) {
        this.handleEndDateSelect(null);
      }
    }
  },
  watch: {
    sortName() {
      this.filteredAlertItems.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          if (!this.sortName) {
            return -1;
          } else {
            return 1;
          }
        }
        if (nameA > nameB) {
          if (!this.sortName) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
    sortCurrentValue() {
      if (!this.sortCurrentValue) {
        this.filteredAlertItems.sort(
          (a, b) =>
            parseFloat(a.current_status.value) -
            parseFloat(b.current_status.value)
        );
      } else if (this.sortCurrentValue) {
        this.filteredAlertItems.sort(
          (a, b) =>
            parseFloat(b.current_status.value) -
            parseFloat(a.current_status.value)
        );
      }
    },

    sortValue() {
      if (!this.sortValue) {
        this.filteredAlertItems.sort(
          (a, b) => parseFloat(b.total_alerts) - parseFloat(a.total_alerts)
        );
      } else if (this.sortValue) {
        this.filteredAlertItems.sort(
          (a, b) => parseFloat(a.total_alerts) - parseFloat(b.total_alerts)
        );
      }
    },

    selectedLayer() {
      const productItem = this.availableProducts.filter(
        item => item.layerId === this.selectedLayer
      );
      this.selectedProduct = productItem[0].product;
    }
  },
  created() {
    if (this.allData) {
      this.selectedDateRange = 10000;
      this.startDate = null;
      this.endDate = null;
    } else if (this.selectedStartDate && this.selectedEndDate) {
      this.selectedDateRange = moment(this.selectedEndDate).diff(
        moment(this.selectedStartDate),
        "days"
      );
      this.startDate = this.selectedStartDate;
      this.endDate = this.selectedEndDate;
      this.selectedReferenceDate = this.endDate;
    } else {
      this.selectedDateRange = 90;
      this.startDate = moment()
        .subtract(90, "days")
        .format("YYYY-MM-DD");
      this.endDate = this.selectedReferenceDate;
      this.setSelectedStartDate(this.startDate);
      this.setSelectedEndDate(this.endDate);
    }

    this.getOrFetchAccessToken().then(() => {
      this.fetchAvailableProducts().then(() => {
        this.fetchProductItems().then(() => {
          if (this.productItems.length) {
            this.selectedLayer = this.productItems[0].layerId;
          }
          this.fetchAlertItems();
          this.fetchVirtualStation();
          this.fetchTimestepsForLayersWithThreshold();
        });
      });
    });
  }
};
</script>

<style scoped>
main {
  height: 100vh;
}

.fill-parent-height {
  height: 100%;
}

.list-items {
  overflow-y: scroll;
}

.top-row {
  min-height: 0;
}
</style>
