const productUnits = {
  abs: "1/m",
  alb: "%",
  aot: null,
  bba: null,
  cdm: "1/m",
  chl: "µg/l",
  chm: "µg/l",
  div: null,
  eva: "mm/h",
  evi: null,
  evd: "mm/day",
  gnd: null,
  gsa: null,
  hab: null,
  kdc: "1/m",
  kdp: "1/m",
  lcc: null,
  lrf: null,
  lwr: null,
  mlo: "",
  ndv: null,
  ndw: null,
  nrf: null,
  otc: null,
  quc: null,
  qut: null,
  rra: null,
  rrs: null,
  sav: null,
  sdd: "m",
  sia: "1/m",
  slp: null,
  soa: "1/m",
  ssr: null,
  sst: "°C",
  swt: "°C",
  tsc: null,
  tsm: "mg/l",
  tss: "mg/l",
  tur: "NTU",
  wex: null,
  wma: null,
  z90: "m"
};

export { productUnits };
