<template>
  <div>
    <template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="file-settings"
            elevation="0"
            fab
            top
            right
            absolute
            v-bind="attrs"
            v-on="on"
            x-small
            color="grey"
          >
            <v-icon @click="uploadFeature">mdi-upload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("monitoring.uploadGeojson") }}</span>
      </v-tooltip>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".geojson,.zip,.json"
        @change="onFileChanged"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shp from "shpjs";

export default {
  name: "UploadFeatures",
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters("draw", ["selectedFeature"]),
    ...mapGetters("management", ["activeRegion"])
  },
  methods: {
    ...mapActions("management", ["setSessionPolygon"]),
    uploadFeature() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    zipShpToGeojson(file) {
      let reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsArrayBuffer(file);
        reader.onerror = () => {
          this.fileLoadError = true;
          reject();
        };
        reader.onload = async event => {
          const geojson = await shp(event.target.result);
          resolve(geojson);
        };
      });
    },
    readGeojson(file) {
      let reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsText(file);
        reader.onerror = () => {
          this.fileLoadError = true;
          reject();
        };
        reader.onload = event => {
          const geojson = JSON.parse(event.target.result);
          resolve(geojson);
        };
      });
    },
    async onFileChanged(e) {
      let geojson;
      this.selectedFile = e.target.files[0];
      if (this.selectedFile.name.endsWith(".zip")) {
        geojson = await this.zipShpToGeojson(this.selectedFile);
      } else if (this.selectedFile.name.endsWith(".geojson")) {
        geojson = await this.readGeojson(this.selectedFile);
      } else if (this.selectedFile.name.endsWith(".json")) {
        geojson = await this.readGeojson(this.selectedFile);
      }
      this.createFeature(geojson);
    },
    createFeature(geojson) {
      this.setSessionPolygon(geojson);
      return geojson;
    }
  }
};
</script>

<style scoped>
.file-settings {
  z-index: 2;
  margin-top: 20px;
  margin-right: -10px;
}
</style>
