<template>
  <v-container
    :style="{ 'background-color': backgroundColor }"
    fluid
    fill-height
  >
    <v-row style="align-items: center">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E9EFF5"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="300"
          height="300"
          rounded
        >
          <v-icon color="#A3B1C7" size="150">mdi-magnify</v-icon>
        </v-card>
        <div v-if="mode == 'alert'">
          <p class="pt-5 font-weight-light text-h5">
            {{ $t("emptyState.noAlertsFound") }}
          </p>
          <p>{{ $t("emptyState.tryAdjusting") }} Alerts</p>
        </div>
        <div v-if="mode == 'plotdata'">
          <p class="pt-5 font-weight-light text-h5">
            {{ $t("emptyState.noResultsFound") }}
          </p>
          <p>{{ $t("emptyState.tryAdjusting") }} data</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "EmptyStateTimeseries",
  props: {
    mode: String,
    layer: { type: String, default: "" },
    startDate: String,
    endDate: String,
    productItems: { type: Array, default: null }
  },
  computed: {
    layerName() {
      const name = this.productItems.filter(
        product => product.layerId == this.layer
      );
      return name[0].title;
    },
    backgroundColor() {
      return this.mode === "alert" ? "#F6F7FB" : "#F6F7FB";
    }
  }
};
</script>

<style scoped></style>
