<template>
  <div style="display: flex">
    <v-icon
      :color="getStatusColor(status.status)"
      v-if="status.status === 'ok'"
    >
      mdi-check-circle
    </v-icon>
    <v-icon color="orange" v-else-if="status.status === 'warning'">
      mdi-alert-circle
    </v-icon>
    <v-icon
      :color="getStatusColor(status.status)"
      v-else-if="status.status === 'alert'"
    >
      mdi-alert
    </v-icon>
    <span
      class="ml-2"
      :title="splitDatetime"
      v-html="value"
      v-if="card === 'hist'"
    ></span>
    <span
      class="ml-2"
      :title="splitDatetime"
      v-html="dateAndValue"
      v-if="card === 'status'"
    ></span>
  </div>
</template>

<script>
import alertMixin from "@/core/mixins/alert.mixin";

export default {
  name: "Status",
  mixins: [alertMixin],
  props: {
    card: {
      type: String
    },
    productUnit: {
      type: String,
      required: false
    },
    status: {
      type: Object,
      required: true,
      default: () => ({
        value: 0,
        scene_date: "",
        status: ""
      })
    }
  },
  computed: {
    splitDatetime() {
      const parts = this.status.scene_date.split("T");
      return `${parts[0]} ${parts[1]}`;
    },
    value() {
      const value = `${this.status.value} ${this.productUnit}`;
      return `${value}`;
    },
    dateAndValue() {
      const parts = this.status.scene_date.split("T");
      const value = `${this.status.value} ${this.productUnit}`;
      return `${parts[0]} </br> ${value}`;
    }
  }
};
</script>

<style scoped></style>
