<template>
  <div>
    <v-card
      class="pa-5 mb-3"
      outlined
      :class="isChildren ? 'ml-5' : ''"
      :style="getAlertBorderStyle"
    >
      <v-row align="center">
        <v-hover v-slot="{ hover }">
          <v-col
            class="row-pointer"
            @click="toMonitoringDetails(alertItem)"
            :class="
              hover
                ? ' font-weight-bold text-decoration-underline'
                : ' font-weight-bold '
            "
            :title="$t('monitoring.toDetailsPage')"
            cols="3"
            >{{ alertItem.name }}
          </v-col>
        </v-hover>

        <v-col cols="3">
          <status
            v-if="this.$appConfig.keycloakClient !== 'coasts'"
            :status="alertItem.current_status"
            :product-unit="productUnit"
            :card="'status'"
          />
        </v-col>
        <v-col cols="3">
          <status
            v-if="this.$appConfig.keycloakClient !== 'coasts'"
            :status="alertItem.previous_status"
            :product-unit="productUnit"
            :card="'status'"
          />
        </v-col>
        <v-hover v-slot="{ hover }">
          <v-card
            text
            class="ml-4 pa-1"
            outlined
            :loading="!histloading"
            :disabled="!histloading"
            @click="showList = !showList"
            :elevation="hover ? 4 : 0"
            :title="$t('monitoring.openHistAlerts')"
          >
            <v-icon color="red">
              mdi-alert
            </v-icon>
            {{ alertItem.total_alerts }} /
            <v-icon color="orange"> mdi-alert-circle </v-icon>
            {{ alertItem.total_warnings }}
          </v-card>
        </v-hover>
        <v-spacer />
      </v-row>

      <v-expand-transition>
        <div v-show="showList" class="pl-2 pr-2 pt-1" v-if="histloading">
          <v-row class="pl-6 pr-5 pt-3" style="font-size: 12px">
            <v-col cols="1"></v-col>
            <v-col cols="5">{{ $t("monitoring.scenedate") }}</v-col>
            <v-spacer />
            <v-col cols="3">{{ $t("monitoring.value") }}</v-col>
            <v-spacer />
          </v-row>
          <v-card
            text
            elevation="0"
            class="hover pa-1 mb-2"
            v-for="(item, i) in states"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :title="$t('monitoring.toDetailsPage')"
            :key="i"
            :to="{
              name: 'MonitoringDetails',
              query: { timestep: item.scene_date },
              params: {
                analysisGeometry: alertItem.analysis_geometry
              }
            }"
          >
            <v-row align="center">
              <v-col cols="1"></v-col>
              <v-col cols="4">{{ item.scene_date.split("T")[0] }}</v-col>
              <v-spacer />
              <v-col cols="3">
                <status
                  :status="item"
                  :product-unit="productUnit"
                  :card="'hist'"
                />
              </v-col>
              <v-spacer />
            </v-row>
            <v-divider />
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import Status from "@/core/components/monitoring/Status";
import alertMixin from "@/core/mixins/alert.mixin";
import { productUnits } from "@/core/services/eomap.services";
import axios from "axios";

export default {
  name: "AlertListItem",
  components: { Status },
  mixins: { alertMixin },
  props: {
    product: {
      type: String,
      required: true
    },
    region: {
      type: Number,
      required: true
    },
    dateRange: {
      type: Number,
      required: true
    },
    referenceDate: {
      type: String,
      required: true
    },
    alertItem: {
      type: Object,
      required: true,
      default: () => ({
        id: 0,
        name: "",
        total_alerts: 0,
        total_warnings: 0,
        current_status: {
          value: 0,
          scene_date: "",
          status: "",
          quality: 0
        },
        previous_status: {
          value: 0,
          scene_date: "",
          status: "",
          quality: 0
        },
        children: [],
        send_notification: true,
        all_status: {}
      })
    },
    isChildren: {
      type: Boolean,
      default: false
    },
    model: {
      type: String,
      default: "1"
    }
  },
  data: function() {
    return {
      showList: false,
      isHovering: false,
      alertHist: {},
      histloading: true
    };
  },
  computed: {
    currentCoverage() {
      return `${this.alertItem.current_status.quality * 100} %`;
    },
    productUnit() {
      return productUnits[this.product] ? `${productUnits[this.product]}` : ``;
    },
    getAlertBorderStyle() {
      const status = this.alertItem.current_status.status;
      const color = alertMixin.methods.getStatusColor(status);
      return `border-left: 4px solid ${color}; border-radius: 3px;`;
    },
    states() {
      return this.alertHist.all_status;
    }
  },
  methods: {
    async fetchAlertHist() {
      const response = await axios.get(
        `alerts/${this.alertItem.id}?days=${this.dateRange}&reference_date=${this.referenceDate}`
      );
      this.alertHist = response.data;
    },

    toMonitoringDetails(alertItem) {
      this.$router.push({
        name: "MonitoringDetails",
        query: { timestep: alertItem.current_status.scene_date },
        params: {
          analysisGeometry: alertItem.analysis_geometry
        }
      });
    }
  },
  watch: {
    showList() {
      this.histloading = false;
      if (this.showList == true) {
        this.fetchAlertHist().then(() => {
          this.histloading = true;
        });
      } else {
        this.histloading = true;
      }
    }
  }
};
</script>

<style scoped>
.hover:hover {
  color: blue;
  elevation: higher;
}

.row-pointer {
  cursor: pointer;
}
</style>
