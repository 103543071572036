var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"pa-5 mb-3",class:_vm.isChildren ? 'ml-5' : '',style:(_vm.getAlertBorderStyle),attrs:{"outlined":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-col',{staticClass:"row-pointer",class:hover
              ? ' font-weight-bold text-decoration-underline'
              : ' font-weight-bold ',attrs:{"title":_vm.$t('monitoring.toDetailsPage'),"cols":"3"},on:{"click":function($event){return _vm.toMonitoringDetails(_vm.alertItem)}}},[_vm._v(_vm._s(_vm.alertItem.name)+" ")])]}}])}),_c('v-col',{attrs:{"cols":"3"}},[(this.$appConfig.keycloakClient !== 'coasts')?_c('status',{attrs:{"status":_vm.alertItem.current_status,"product-unit":_vm.productUnit,"card":'status'}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}},[(this.$appConfig.keycloakClient !== 'coasts')?_c('status',{attrs:{"status":_vm.alertItem.previous_status,"product-unit":_vm.productUnit,"card":'status'}}):_vm._e()],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"ml-4 pa-1",attrs:{"text":"","outlined":"","loading":!_vm.histloading,"disabled":!_vm.histloading,"elevation":hover ? 4 : 0,"title":_vm.$t('monitoring.openHistAlerts')},on:{"click":function($event){_vm.showList = !_vm.showList}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert ")]),_vm._v(" "+_vm._s(_vm.alertItem.total_alerts)+" / "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.alertItem.total_warnings)+" ")],1)]}}])}),_c('v-spacer')],1),_c('v-expand-transition',[(_vm.histloading)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showList),expression:"showList"}],staticClass:"pl-2 pr-2 pt-1"},[_c('v-row',{staticClass:"pl-6 pr-5 pt-3",staticStyle:{"font-size":"12px"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.$t("monitoring.scenedate")))]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.$t("monitoring.value")))]),_c('v-spacer')],1),_vm._l((_vm.states),function(item,i){return _c('v-card',{key:i,staticClass:"hover pa-1 mb-2",attrs:{"text":"","elevation":"0","title":_vm.$t('monitoring.toDetailsPage'),"to":{
            name: 'MonitoringDetails',
            query: { timestep: item.scene_date },
            params: {
              analysisGeometry: _vm.alertItem.analysis_geometry
            }
          }},on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(item.scene_date.split("T")[0]))]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('status',{attrs:{"status":item,"product-unit":_vm.productUnit,"card":'hist'}})],1),_c('v-spacer')],1),_c('v-divider')],1)})],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }