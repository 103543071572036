<template>
  <v-container fluid fill-height>
    <v-row style="align-items: center">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E9EFF5"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="300"
          height="300"
          rounded
        >
          <v-icon color="#A3B1C7" size="120">mdi-pencil</v-icon>
        </v-card>
        <p class="mt-5 text-h5">{{ $t("baseline.almostThere") }}</p>
        <p>{{ $t("emptyState.pleaseAddThresholds") }}</p>
        <v-btn
          color="#039BE5"
          rounded
          class="white--text font-weight-bold button-text"
          :to="{ name: 'GlobalsThresholds' }"
          >{{ $t("emptyState.setThresholds") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EmptyStateThresholds"
};
</script>

<style scoped>
.button-text {
  text-transform: unset !important;
}
</style>
