let alertMixin = {
  methods: {
    getStatusIcon(status) {
      if (status === "warning") {
        return "mdi-alert-circle";
      } else if (status === "alert") {
        return "mdi-alert";
      } else {
        return "mdi-check-circle";
      }
    },
    getStatusColor(status) {
      if (status === "warning") {
        return "#ec6a18";
      } else if (status === "alert") {
        return "#f24726";
      } else {
        return "#8fd14f";
      }
    }
  }
};

export default alertMixin;
