<template>
  <div id="dashboardMap" ref="dashboardMap" class="map">
    <UploadFeatures></UploadFeatures>
  </div>
</template>

<script>
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import polylabel from "polylabel";
import { updateMarkers } from "@/core/components/monitoring/alertcluster";
import { mapState } from "vuex";
import mapboxgl from "mapbox-gl";
import store from "@/core/store";
import i18n from "@/localization/i18n";
import vuetify from "@/core/plugins/vuetify";
import router from "@/core/router";
import Vue from "vue";
import AlertPopup from "@/core/components/monitoring/AlertPopup.vue";
import UploadFeatures from "@/core/components/monitoring/UploadFeatures.vue";
const statusOk = ["==", ["get", "currentStatus"], "ok"];
const statusWarning = ["==", ["get", "currentStatus"], "warning"];
const statusAlert = ["==", ["get", "currentStatus"], "alert"];
const colors = ["#8fd14f", "#ff944d", "#f24726", "#e7e4e4"];

const VueAlertPopupComponent = Vue.extend(AlertPopup);

export default {
  name: "DashboardMap",
  components: { UploadFeatures },
  props: {
    alertItems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    accessToken: MapboxConfig.accessToken,
    style: "mapbox://styles/eomap/ckq7xvq6s068c17qwcjbmc7wx",
    alertItemsPointLayer: {
      id: "alertItemsPointLayer",
      type: "circle",
      source: "alertItemsPointSource",
      filter: ["!=", "cluster", true],
      paint: {
        "circle-color": [
          "case",
          statusOk,
          colors[0],
          statusWarning,
          colors[1],
          statusAlert,
          colors[2],
          colors[3]
        ],
        "circle-opacity": 1,
        "circle-radius": 20
      }
    },
    alertItemsLabelLayer: {
      id: "alertItemsLabelLayer",
      type: "symbol",
      source: "alertItemsPointSource",
      filter: ["!=", "cluster", true],
      layout: {
        "text-field": [
          "number-format",
          ["get", "currentValue"],
          { "min-fraction-digits": 1, "max-fraction-digits": 1 }
        ],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-size": 12
      },
      paint: {
        "text-color": "black"
      }
    },
    alertItemsPolygonLayer: {
      id: "alertItemsPolygonLayer",
      type: "line",
      source: "alertItemsPolygonSource",
      filter: ["!=", "cluster", true],
      paint: {
        "line-color": "#2E4083",
        "line-width": 1
      },
      layout: { visibility: "none" }
    },
    lineGeojson: {
      id: "lineGeojsonLayer",
      type: "line",
      source: "lineGeojsonSource",
      layout: {
        visibility: `visible`
      },
      paint: {
        "line-color": "#2E4083",
        "line-width": 3
      }
    },
    alertPopup: {
      coordinates: [0, 0],
      properties: {
        currentDate: "",
        currentStatus: "",
        currentValue: 0,
        previousDate: "",
        previousStatus: "",
        previousValue: 0,
        name: "",
        totalAlerts: 0,
        totalWarnings: 0
      }
    },
    loading: true,
    popUpRefs: []
  }),
  methods: {
    initMap() {
      this.loading = true;
      mapboxgl.accessToken = MapboxConfig.accessToken;
      const bounds = this.alertItemsBounds;
      const map = new mapboxgl.Map({
        container: "dashboardMap",
        style: this.style,
        bounds: bounds
      });
      this.map = map;

      map.on("load", () => {
        if (this.polygonFeature.features) {
          this.addFeature(this.polygonFeature, this.lineGeojson, "lineGeojson");
        }
        this.addFeature(
          this.alertItemsPolygonSource.data,
          this.alertItemsPolygonLayer,
          "alertItemsPolygon"
        );
        this.addFeature(
          this.alertItemsPointSource.data,
          this.alertItemsPointLayer,
          "alertItemsPoint"
        );
        this.addFeature(
          this.alertItemsPointSource.data,
          this.alertItemsLabelLayer,
          "alertItemsLabel"
        );
      });

      map.fitBounds(this.alertItemsBounds, { duration: 0 });
      this.addPopupClickEvent(map);
      map.on("render", () => {
        updateMarkers(map, colors);
        this.changeCursorOnAlertLayer(map);
      });

      map.on("zoomend", () => {
        const zoomLevel = map.getZoom();
        if (zoomLevel > 9) {
          map.setLayoutProperty(
            "alertItemsPolygonLayer",
            "visibility",
            "visible"
          );
        }
        if (zoomLevel <= 9) {
          map.setLayoutProperty("alertItemsPolygonLayer", "visibility", "none");
        }
      });

      this.$watch("alertItemsBounds", bounds => {
        map.fitBounds(bounds, { duration: 0 });
      });
      this.loading = false;
    },
    itemToFeature(alertItem) {
      return {
        id: alertItem.id,
        geometry: alertItem.geometry,
        properties: {
          name: alertItem.name,
          totalAlerts: alertItem.total_alerts,
          totalWarnings: alertItem.total_warnings,
          currentStatus: alertItem.current_status.status,
          currentValue: alertItem.current_status.value,
          currentDate: alertItem.current_status.scene_date,
          previousStatus: alertItem.previous_status.status,
          previousValue: alertItem.previous_status.value,
          previousDate: alertItem.previous_status.scene_date
        }
      };
    },
    changeCursorOnAlertLayer(map) {
      map.on("mouseenter", "alertItemsPointLayer", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "alertItemsPointLayer", () => {
        map.getCanvas().style.cursor = "";
      });
    },
    addPopupClickEvent(map) {
      map.on("click", "alertItemsPointLayer", e => {
        this.showPopup(e);
      });
    },
    showPopup(event) {
      this.coordinates = event.lngLat;
      this.pixelCoordinates = event.point;
      this.popupProperties = event.features[0].properties;

      let popUpObject = new mapboxgl.Popup()
        .setLngLat(this.coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .addTo(this.map);
      this.$options.popUp = popUpObject;
      this.createAlertPopup(popUpObject);
    },
    createAlertPopup(popUpObject) {
      const ref = new VueAlertPopupComponent({
        el: "#vue-popup-content",
        store,
        i18n,
        vuetify,
        router,
        propsData: {
          close: () => this.closePopup(popUpObject),
          alertProperties: this.popupProperties
        }
      });
      popUpObject._update();
      this.popUpRefs.push(ref);
    },

    closePopup(popup) {
      this.popupProperties = {
        currentDate: "",
        currentStatus: "",
        currentValue: 0,
        previousDate: "",
        previousStatus: "",
        previousValue: 0,
        name: "",
        totalAlerts: 0,
        totalWarnings: 0
      };
      popup.remove();
    },
    addFeature(source, layer, name) {
      if (this.map.getLayer(`${name}Layer`)) {
        this.map.removeLayer(`${name}Layer`);
      }
      if (this.map.getSource(`${name}Source`)) {
        this.map.removeSource(`${name}Source`);
      }
      if (name === "alertItemsPoint") {
        this.map.addSource(`${name}Source`, {
          type: "geojson",
          data: source,
          cluster: true,
          clusterRadius: 80,
          clusterProperties: {
            statusOk: ["+", ["case", statusOk, 1, 0]],
            statusWarning: ["+", ["case", statusWarning, 1, 0]],
            statusAlert: ["+", ["case", statusAlert, 1, 0]]
          }
        });
      } else {
        this.map.addSource(`${name}Source`, {
          type: "geojson",
          data: source
        });
      }

      this.map.addLayer(layer);
    }
  },
  computed: {
    ...mapState("management", ["sessionPolygon"]),
    alertItemsPolygonFeatureCollection() {
      let geojson = {
        type: "FeatureCollection",
        features: []
      };
      this.alertItems.forEach(alertItem => {
        geojson.features.push(this.itemToFeature(alertItem));

        if (alertItem.children) {
          alertItem.children.forEach(alertItemChild => {
            geojson.features.push(this.itemToFeature(alertItemChild));
          });
        }
      });
      return geojson;
    },
    alertItemsPointFeatureCollection() {
      let geojson = {
        type: "FeatureCollection",
        features: []
      };
      this.alertItemsPolygonFeatureCollection.features.forEach(feature => {
        let pointCoords = [];
        if (feature.geometry.type === "Polygon") {
          pointCoords = polylabel(feature.geometry.coordinates);
        } else if (feature.geometry.type === "Point") {
          pointCoords = feature.geometry.coordinates;
        } else {
          pointCoords = polylabel(feature.geometry.coordinates);
        }
        let pointFeature = { ...feature };
        const pointGeometry = {
          type: "Point",
          coordinates: [pointCoords[0], pointCoords[1]]
        };
        pointFeature.geometry = pointGeometry;
        geojson.features.push(pointFeature);
      });
      return geojson;
    },
    alertItemsPointSource() {
      return {
        type: "geojson",
        data: this.alertItemsPointFeatureCollection,
        cluster: true,
        clusterRadius: 80,
        clusterProperties: {
          statusOk: ["+", ["case", statusOk, 1, 0]],
          statusWarning: ["+", ["case", statusWarning, 1, 0]],
          statusAlert: ["+", ["case", statusAlert, 1, 0]]
        }
      };
    },
    alertItemsPolygonSource() {
      return {
        type: "geojson",
        data: this.alertItemsPolygonFeatureCollection
      };
    },
    alertItemsBounds() {
      let boundingBox = [-90, 90, 90, -90];
      if (this.alertItemsPolygonFeatureCollection.features.length) {
        boundingBox = bbox(this.alertItemsPolygonFeatureCollection);
      }
      return boundingBox;
    },
    polygonFeature() {
      return this.sessionPolygon;
    }
  },
  watch: {
    sessionPolygon() {
      this.polygonFeature = this.sessionPolygon;
      this.initMap();
    },
    alertItems() {
      if (this.alertItems.length > 0) {
        this.initMap();
      }
    }
  }
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
