import { mapActions, mapState } from "vuex";

let dashboardMixin = {
  methods: {
    ...mapActions("dashboard", ["setFirstSceneDate"]),

    async fetchTimestepsForLayersWithThreshold() {
      let allDates = [];

      const requests = this.layersWithRegionThreshold.map(layerId =>
        this.$rastless
          .get(
            `/layers/${layerId}/steps?pagination=true&num_items=900&token=${this.accessToken}`
          )
          .then(async response => {
            response.data.forEach(element => {
              let date = element.datetime.split("T")[0];
              allDates.push(date);
            });
          })
      );
      await Promise.allSettled(requests);

      const uniqueDatetimes = new Set(allDates);

      let firstDate = Array.from(uniqueDatetimes).sort(
        (a, b) => new Date(a) - new Date(b)
      )[0];
      this.setFirstSceneDate(firstDate);
    }
  },
  computed: {
    ...mapState("dashboard", ["layersWithRegionThreshold"])
  }
};

export default dashboardMixin;
