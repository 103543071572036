<template>
  <v-card min-width="300" elevation="0">
    <v-card-title>
      {{ alertProperties.name }}
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="font-weight-bold ml-8 mb-2">
        {{ $t("today") }}
      </div>
      <v-timeline align-top dense>
        <v-timeline-item
          :icon-color="getStatusColor(alertProperties.currentStatus)"
          :icon="getStatusIcon(alertProperties.currentStatus)"
          color="white"
          fill-dot
        >
          <div>
            <div>
              {{
                `${alertProperties.currentDate.split("T")[0]}, ${
                  alertProperties.currentDate.split("T")[1]
                }`
              }}
            </div>
            <div class="text--primary">
              {{ $t("value") }}:
              <strong>{{ alertProperties.currentValue }}</strong>
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item
          :icon-color="getStatusColor(alertProperties.previousStatus)"
          :icon="getStatusIcon(alertProperties.previousStatus)"
          color="white"
          fill-dot
        >
          <div>
            <div>
              {{
                `${alertProperties.previousDate.split("T")[0]}, ${
                  alertProperties.previousDate.split("T")[1]
                }`
              }}
            </div>
            <div class="text--primary">
              {{ $t("value") }}:
              <strong>{{ alertProperties.previousValue }}</strong>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <div class="text--primary pt-2">
        {{ $t("totalAlerts") }}:
        <strong>{{ alertProperties.totalAlerts }}</strong
        ><br />
        {{ $t("totalWarnings") }}:
        <strong>{{ alertProperties.totalWarnings }}</strong>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import alertMixin from "@/core/mixins/alert.mixin";

export default {
  name: "AlertPopup",
  mixins: [alertMixin],
  props: {
    close: Function,
    alertProperties: {
      type: Object,
      default: () => ({
        currentDate: "",
        currentStatus: "",
        currentValue: 0,
        previousDate: "",
        previousStatus: "",
        previousValue: 0,
        name: "",
        totalAlerts: 0,
        totalWarnings: 0
      })
    }
  }
};
</script>

<style>
.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 400px !important;
}
</style>
